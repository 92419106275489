import React from "react";
import LoadingDialog from './../LoadingDialog'
import Comment from './../Comment'
import DayFilter from './../DayFilter'
import popupIco from 'popup-ico.svg';
import {popupCenter} from './../popup/popupCenter'
import olhoOn from 'bt_olho_on.svg'
import olhoOff from 'bt_olho_off.svg'

import ResultChartDay from "../ResultChartDay";
import downloadIco from 'bt_download.svg';
import DetailingResult from "../DetailingResult";
import ManualLaunch from "../ManualLaunch";
import MonthRangeInput from "../MonthRangeInput"


class Balance extends React.Component {

  constructor(props){
  	super(props)
  	this.requestsCounter = 0
  	this.state ={
      days:  props.days,
			lineParams: {},
      recordsFetchedByYear: {},
      liquidProfitFormula: {},
      allYearsData: {},
      showZeroLines: false,
      value_initial_balance: 0,
      day_number_selected: 0,
      allData: {},
  		year: this.props.defaultYear,
  		month: this.props.defaultMonth,
        default_days: {},
        default_weeks: {},
        days_foreseen_accomplished: {},
      code_contabil_pattern_param_list: {},
  		activeBranches: {},
      recordsFetched: {},
      activeKeys: {},
  		loading: false,
  		data: [],
        allDataYears: [],
  		data_balance: [],
  		years: [],
  		resultIds: [],
  		cash_flow_formula_ids: {},
      activeRows: {},
      periods: {},
			visibleParams: {},
      visibleDays: {
        "1" : true,
        "2" : true,
        "3" : true,
        "4" : true,
        "5" : true,
        "6" : true,
        "7" : true,
        "8" : true,
        "9" : true,
        "10":  true,
        "11":  true,
        "12":  true,
        "13":  true,
        "14":  true,
        "15":  true,
        "16":  true,
        "17":  true,
        "18":  true,
        "19":  true,
        "20":  true,
        "21":  true,
        "22":  true,
        "23":  true,
        "24":  true,
        "25":  true,
        "26":  true,
        "27":  true,
        "28":  true,
        "29":  true,
        "30":  true ,
        "31":  true
      },
      months: props.months,
      result_operational_day: {
        "1" : 0,
        "2" : 0,
        "3" : 0,
        "4" : 0,
        "5" : 0,
        "6" : 0,
        "7" : 0,
        "8" : 0,
        "9" : 0,
        "10":  0,
        "11":  0,
        "12":  0,
        "13":  0,
        "14":  0,
        "15":  0,
        "16":  0,
        "17":  0,
        "18":  0,
        "19":  0,
        "20":  0,
        "21": 0,
        "22":  0,
        "23":  0,
        "24":  0,
        "25":  0,
        "26":  0,
        "27":  0,
        "28":  0,
        "29":  0,
        "30":  0,
        "31":  0
      },
      result_not_operational_day:  {
        "1" : 0,
        "2" : 0,
        "3" : 0,
        "4" : 0,
        "5" : 0,
        "6" : 0,
        "7" : 0,
        "8" : 0,
        "9" : 0,
        "10":  0,
        "11":  0,
        "12":  0,
        "13":  0,
        "14":  0,
        "15":  0,
        "16":  0,
        "17":  0,
        "18":  0,
        "19":  0,
        "20":  0,
        "21":  0,
        "22":  0,
        "23":  0,
        "24":  0,
        "25":  0,
        "26":  0,
        "27":  0,
        "28":  0,
        "29":  0,
        "30":  0,
        "31":  0
     },
      initial_balance_day: {
        "1" : 0,
        "2" : 0,
        "3" : 0,
        "4" : 0,
        "5" : 0,
        "6" : 0,
        "7" : 0,
        "8" : 0,
        "9" : 0,
        "10":  0,
        "11":  0,
        "12":  0,
        "13":  0,
        "14":  0,
        "15":  0,
        "16":  0,
        "17":  0,
        "18":  0,
        "19":  0,
        "20":  0,
        "21":  0,
        "22":  0,
        "23":  0,
        "24":  0,
        "25":  0,
        "26":  0,
        "27":  0,
        "28":  0,
        "29":  0,
        "30":  0,
        "31":  0
      },
      final_balance_day: {
        "1" : 0,
        "2" : 0,
        "3" : 0,
        "4" : 0,
        "5" : 0,
        "6" : 0,
        "7" : 0,
        "8" : 0,
        "9" : 0,
        "10":  0,
        "11":  0,
        "12":  0,
        "13":  0,
        "14":  0,
        "15":  0,
        "16":  0,
        "17":  0,
        "18":  0,
        "19":  0,
        "20":  0,
        "21":  0,
        "22":  0,
        "23":  0,
        "24":  0,
        "25":  0,
        "26":  0,
        "27":  0,
        "28":  0,
        "29":  0,
        "30":  0,
        "31":  0
      },
      result_months: {
        "1" : 0,
        "2" : 0,
        "3" : 0,
        "4" : 0,
        "5" : 0,
        "6" : 0,
        "7" : 0,
        "8" : 0,
        "9" : 0,
        "10":  0,
        "11":  0,
        "12":  0,
        "13":  0,
        "14":  0,
        "15":  0,
        "16":  0,
        "17":  0,
        "18":  0,
        "19":  0,
        "20":  0,
        "21":  0,
        "22":  0,
        "23":  0,
        "24":  0,
        "25":  0,
        "26":  0,
        "27":  0,
        "28":  0,
        "29":  0,
        "30":  0,
        "31":  0
      },
      vls_saldo_bank: {   "1" : 0,
        "2" : 0,
        "3" : 0,
        "4" : 0,
        "5" : 0,
        "6" : 0,
        "7" : 0,
        "8" : 0,
        "9" : 0,
        "10":  0,
        "11":  0,
        "12":  0,
        "13":  0,
        "14":  0,
        "15":  0,
        "16":  0,
        "17":  0,
        "18":  0,
        "19":  0,
        "20":  0,
        "21":  0,
        "22":  0,
        "23":  0,
        "24":  0,
        "25":  0,
        "26":  0,
        "27":  0,
        "28":  0,
        "29":  0,
        "30":  0,
        "31":  0},
      check_bank: {   "1" : 0,
        "2" : 0,
        "3" : 0,
        "4" : 0,
        "5" : 0,
        "6" : 0,
        "7" : 0,
        "8" : 0,
        "9" : 0,
        "10":  0,
        "11":  0,
        "12":  0,
        "13":  0,
        "14":  0,
        "15":  0,
        "16":  0,
        "17":  0,
        "18":  0,
        "19":  0,
        "20":  0,
        "21":  0,
        "22":  0,
        "23":  0,
        "24":  0,
        "25":  0,
        "26":  0,
        "27":  0,
        "28":  0,
        "29":  0,
        "30":  0,
        "31":  0}

  	}
  }
  async fetchDataParams(year=null, month,label){

    console.log("fetchDataParams ")
    this.setState({loading: true})
    await axios.get('/results/get_result_chart_balance.json',{params: {
        balence_type: "day_total_balance",
        month: month
      }}).then(response => {
      this.setState({allData:  response.data.result })
      this.setState({allDataYears:  response.data.years })
    })



    /*if(label ==="Operacional" ){
      //  allData[year][month] = {"result_operational":  this.state.result_operational}
      this.setState((oldState)=>{
        oldState.allData[year][month] = {"result_operational":  this.state.result_operational_day}
        return {...oldState}
      })
    }else{
      if(label ==="Não Operacional" ){
        this.setState((oldState)=>{
          oldState.allData[year][month] = {"result_not_operational":  this.state.result_not_operational_day}
          return {...oldState}
        })
      }else{
        if(label ==="initial_balance"){
          this.setState((oldState)=>{
            oldState.allData[year][month] = {"initial_balance":  this.state.initial_balance_day}
            return {...oldState}
          })
        }else{
          if(label ==="final_balance"){
            this.setState((oldState)=>{
              oldState.allData[year][month] = {"final_balance":  this.state.final_balance_day}
              return {...oldState}
            })
          }else{
            if(label ==="check_bank"){
              this.setState((oldState)=>{
                oldState.allData[year][month] = {"check_bank":  this.state.check_bank}
                return {...oldState}
              })
            }else{
              if(label ==="vls_saldo_bank"){
                this.setState((oldState)=>{
                  oldState.allData[year][month] = {"vls_saldo_bank":  this.state.vls_saldo_bank}
                  return {...oldState}
                })
              }else{
                if(label ==="result_months"){
                  this.setState((oldState)=>{
                    oldState.allData[year][month] = {"result_months":  this.state.result_months}
                    return {...oldState}
                  })
                }
              }
            }
          }
        }

      }
    } */





  }

  async fetchInitialParams(year=null,changeData=true, month=null){
  	this.setState({loading: true})
  	this.requestsCounter += 1
	  let result = await axios.get('/results/balances_by_day.json', {params: {
      year: year,
      month: month
    }})
    this.requestsCounter -= 1
    this.setState({loading: false})
    if(changeData){
      this.setState({cash_flow_formula_ids: result.data.cash_flow_formula_ids})
      this.setState({data: result.data.params})
      this.setState({result: result.data.result})
      this.setState({resultIds: {}})
    }
    this.setState({allYearsData:{
      ...this.state.allYearsData,
      [year]: result.data.result
    }})
	
  }


  async fetchDetail(key, year=this.state.year, hide=true, updateLocal=true, month = this.state.month){
    const {recordsFetched} = this.state;

    if(recordsFetched[key] && hide){
      this.setState((oldState)=>{
        oldState.activeKeys[key] = {} 
        oldState.recordsFetched[key] = null
        return {...oldState}
      })
    }else{
      this.setState({loading: true})
      this.requestsCounter += 1
      let details = await axios.get(`/results/${key}/detail.json`, {params: {
          year: year,
          value_param: 'f_saldo_atual',
          type: 'day',
          month: month
      }})
      this.requestsCounter -= 1
      this.setState({loading: false})
      
      this.setState((oldState)=>{
        let dataFetched = {}
        if (Object.values(Object.values(details.data)[0].childreen).length > 0){
          dataFetched = Object.values(details.data)[0].childreen
        }else{
          dataFetched = Object.values(details.data)[0].lines
        }

        if(updateLocal){
          oldState.recordsFetched[key] = dataFetched
        }
        
        oldState.recordsFetchedByYear[year] ||= {}
        oldState.recordsFetchedByYear[year][key] = dataFetched
        return {...oldState}
      })
    }
    
  }

	async fetchDataColumnsPatterns() {
		await axios.get(`/results/columns_patterns.json`)
			.then(response => {
				console.log(response.data.columns_patterns)
				console.log(response.data.visible_params)
				this.setState({lineParams: response.data.columns_patterns})
				this.setState({visibleParams: response.data.visible_params});
			})
			.catch(error => {
				console.log(error.response)
			});

	}

  async setComments(){
    
    let comments = await axios.get('/comments.json', {params:{
      year: [this.state.year, 0]
    }})  
    
    
    this.setState({comments: comments.data})
  }

  async getCheckBank(year, month) {
    try {
      const res = await axios.get(`/results/get_bank_statements_day.json`, {
        params: {
          year: year,
          month: month
        }
      });
  
      const updatedVlsSaldoBank = {};
  
      Object.entries(this.state.days).forEach(([name, number]) => {
        const value = res.data.values[number];
        updatedVlsSaldoBank[name] = value;
      });
  
      this.setState((oldState) => ({
        ...oldState,
        vls_saldo_bank: { ...oldState.vls_saldo_bank, ...updatedVlsSaldoBank }
      }));
    } catch (error) {
      console.error('Erro ao buscar os dados do banco:', error);
    }
  }

  async exportResult(month, year, initial_balance, final_balance, result_operational, result_not_operational, vls_saldo_bank, check_bank) {
    this.setState({ loading: true });
    this.requestsCounter += 1;
  
    try {
      const response = await axios.get(`/export/export_balance`, {
        params: {
          year,
          month,
          type_export: 'days',
          initial_balance,
          final_balance,
          result_operational,
          result_not_operational,
          vls_saldo_bank,
          check_bank,
        },
        responseType: 'blob',
      });
  
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'download.xlsx';
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="(.+)"/);
        if (match && match[1]) {
          filename = match[1];
        }
      }
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Erro ao exportar o arquivo: ", error);
    } finally {
      this.requestsCounter -= 1;
      this.setState({ loading: false });
    }
  }

  async setYears(){
  	this.requestsCounter += 1
  	let years = await axios.get('/balance_sheets/years.json')
  	this.requestsCounter -= 1
  	this.setState({years: years.data})
    this.setComments()

  }
  async setDays(year, month){

    await axios.get(`/results/days.json`, {
        params: {
          month: month,
          year: year
        }
    }).then(response => {
      this.setState({days:  response.data.days})
      this.setState({default_weeks:  response.data.days_of_the_week })
      this.setState({days_foreseen_accomplished:  response.data.days_foreseen_accomplished })


    })

  }
  async get_code_contabil_pattern_param_list(year, month){

    await axios.get(`/results/get_code_contabil_pattern_param_list.json`, {
      params: {
        year: year,
        month: month,
        type: "month"
      }
    }).then(response => {
      this.setState({code_contabil_pattern_param_list:  response.data.code_contabil_pattern_param_list })

    })

  }
  componentDidMount(){

		this.fetchDataColumnsPatterns()
  	this.setYears()
    this.setComments()
    this.fetchConflicts()

    this.setDays(this.state.year, this.state.month)
  	this.fetchInitialParams(this.state.year, true,this.state.month )
    this.get_code_contabil_pattern_param_list(this.state.year, this.state.month )

    this.fetchGetPeriods()
    this.getFinalBalance(this.state.year, this.state.month )
    this.getCheckBank(this.state.year, this.state.month)
  }

  numberToCurrency(number){
  	if(number == 0 || number){
  		return parseFloat(number).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })	
  	}
  	return "-"
	
  }
  howDays(month){
    let days = {}


    if (month === "fevereiro"){
      days = { "1" : 1, "2" : 2, "3" : 3, "4" : 4, "5" : 5, "6" : 6, "7" : 7, "8" : 8, "9" : 9, "10":  10, "11":  11, "12":  12, "13":  13, "14":  14, "15":  15, "16":  16, "17":  17, "18":  18, "19":  19, "20":  20, "21": 21, "22":  22, "23":  23, "24":  24, "25":  25, "26":  26, "27":  27, "28":  28, "29":  29}
    }else{
      days = { "1" : 1, "2" : 2, "3" : 3, "4" : 4, "5" : 5, "6" : 6, "7" : 7, "8" : 8, "9" : 9, "10":  10, "11":  11, "12":  12, "13":  13, "14":  14, "15":  15, "16":  16, "17":  17, "18":  18, "19":  19, "20":  20, "21": 21, "22":  22, "23":  23, "24":  24, "25":  25, "26":  26, "27":  27, "28":  28, "29":  29, "30": 30 , "31": 31}
    }
    return days
  }
  removeAllChildren(row, rows){
    if(row.childreen_attributes.length > 0){
      rows[row.id] = false
      row.childreen_attributes.map((child)=>(this.removeAllChildren(child, rows)))
      
    }else{
      rows[row.id] = false
    }
  }


  toggleRow(parent, row){
    
  	let rows = this.state.activeBranches
  	rows[row.id] = !rows[row.id]
    if(!rows[row.id]){
      this.removeAllChildren(row, rows)  
    }
    
  	this.setState({activeBranches: rows})
  }

  branchIsActive(row){
  	return this.state.activeBranches[row.id]
  }

  sumResults(values){
  	let result = Object.values(Object.values(values)).reduce((a,b)=> parseFloat(a)+ parseFloat(b), 0)
  	return this.numberToCurrency(result)
  	// return 0
  }

  renderChildreenFor(key=null, level){
    
    let data = this.state.recordsFetched[key]

    if(data){
      return (<React.Fragment>
        {Object.values(data).map((row)=>(this.renderRow(row, level, key)))}
      </React.Fragment>)
    }
  }

  renderRow(row, level=0, key=null){
    
    let childrenLines = (entry, month_name)=>{
      try{
        let initial = Object.values(entry.lines).map((x)=> x.months[month_name]).filter((a)=> parseFloat(a).toString() != "NaN").reduce((a,b)=> parseFloat(a) + parseFloat(b), 0)

        if (Object.values(entry.childreen).length > 0) {
          Object.values(entry.childreen).map((child)=> {
            initial += childrenLines(child, month_name)
          })
        }

        return initial
      }
      catch(e){
        console.log("ERRO AQUI", e)
        return 0
      }
      
    }

    const { months} = this.props;
    const  {costCentersFetched, activeKeys,month, days} = this.state;



    let sum = 0

    return <React.Fragment>
        {!row.months ?
          <tr>
            <td ><div 
                onClick={()=> {
                  
                  this.setState((oldState)=>{
                    if(row.label){
                      
                      oldState.activeKeys[key] = oldState.activeKeys[key] ? oldState.activeKeys[key] : {}
                      oldState.activeKeys[key][row.label] = oldState.activeKeys[key][row.label] ? null : {}
                    }else{
                      oldState.activeKeys[key] = oldState.activeKeys[key] ? null : {}  
                    }
                    
                    return {...oldState}
                  })
                }}
                style={{paddingLeft: level * 10}}>
                  {row.label}
                </div>
            </td>
            {Object.entries(days).map(([name, number]) => {
              let monthValue = childrenLines(row, name)
              if(monthValue.toString() != "NaN"){
                sum += monthValue  
              }
              
              console.log(sum)
              return (
                <td style={{paddingLeft: "76px !important"}} className="comment-wrapper">
                  {/*this.state.comments && <Comment
                    refreshComments={()=> this.setComments()}
                    comments={this.state.comments}
                    model_reference={'balance_sheet_row'}
                    month={number}
                    year={this.state.year}
                    model_reference_id={key}>
                  </Comment>*/}
                  {this.numberToCurrency(monthValue)}
                </td>
              );
            })}
            <td>
              <ResultChartDay
                  loadAllYears={()=>{
                    this.state.years.map((year)=>{
                      this.state.months.map((month)=>{
                        this.fetchDetail(key, year, false, false,  month )
                      })
                    })
                  }}
                  loadYear={(year)=> {
                    this.fetchDetail(key, year, false, false,  this.state.month)
                  }}
                  years={this.state.years}
                  year={this.state.year}
                  title={row.label}
                  line={row}
                  allYearsData={this.state.recordsFetchedByYear}
                  dataKey={"balance-detail-line"}
                  days={this.state.days}
                  month={this.state.month}
              />
            </td>
            <td>
              {this.numberToCurrency(sum)}
            </td>
        </tr>
      :
        this.renderLine(row, level, key)
      }

      
      {activeKeys[key] && row.childreen && Object.values(row.childreen).map((child)=>{
          return this.renderRow(child, level + 1, key)
        })}
      {(activeKeys[key] && activeKeys[key][row.label]) && row.lines &&  Object.keys(row.childreen).length < 1 && Object.values(row.lines).map((line)=>{
          return this.renderLine(line, level + 1, key)
        })}
      </React.Fragment>
  }

  removeSufix(code){
    let {sufix} = this.props;

    return code.replace("-"+sufix, "")
  }

  renderLine(line, level=0, key){
    const { months, year, month , days} = this.state;

    let totalLine = 0
    line.id = this.removeSufix(line.id)
    return <tr className="line">
      <td>
        <div style={{paddingLeft:  65 + (level * 5)}}>{line.label} </div>
      </td>
      {Object.entries(days).map(([name, number]) => {
          if(parseFloat(line.days[name]).toString() !== "NaN"){
            totalLine += parseFloat(line.days[name])
          }
          
          return (
            <td style={{paddingLeft: "76px !important"}} className="comment-wrapper">
              {/*this.state.comments && <Comment
                refreshComments={()=> this.setComments()}
                comments={this.state.comments}
                model_reference={'balance_sheet_line'}
                month={number}
                year={this.state.year}
                model_reference_id={line.id}>
              </Comment> */}

              {<DetailingResult
                  refreshDetailingResults={()=> this.setDetailingResults()}
                  detailingResults={this.state.comments}
                  model_reference={'balance_sheet'}
                  month={this.state.month}
                  monthName={number}
                  type_detailing_result={"line"}
                  type_result={"day"}
                  year={this.state.year}
                  model_reference_id={line.id}
                  model_reference_label={line.label}
									lineParams={this.state.lineParams}
									visibleParams={this.state.visibleParams}
                  reference_id={line.id}>
              </DetailingResult>}
              {line.days[name] ? (     parseFloat(line.days[name]) ).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL"
              }) : "-"}
            </td>
          );
        })}
      <td >

        <ResultChartDay
            loadAllYears={()=>{
              this.state.years.map((year)=>{
                this.state.months.map((month)=>{
                  this.fetchDetail(key, year, false, false,  month )
                })
              })
            }}
            loadYear={(year)=> {
              this.fetchDetail(key, year, false, false,  this.state.month)
            }}
            years={this.state.years}
            year={this.state.year}
            title={line.label}
            line={line}
            allYearsData={this.state.recordsFetchedByYear}
            dataKey={"balance-detail-line"}
            days={this.state.days}
            month={this.state.month}
        />
      </td>
    </tr>
  }

  getDataForParam(param){
    const {result, resultIds, cash_flow_formula_ids, showZeroLines, recordsFetched} = this.state;
    console.log(result.params[param.id])
    
    return result.params[param.id]
  }

  renderChildreen(contabilParam, childreen_attributes=[], level=0, signal = '+'){
  	const {months, month, days} = this.state;
    //let days = this.howDays(month)

  	const {result, resultIds, cash_flow_formula_ids, showZeroLines, recordsFetched, code_contabil_pattern_param_list} = this.state;
  	let childreen = null

    //contabilParam.label === 'Saídas'
	  childreen = childreen_attributes.map((child)=> {
      //let empty = true
      let empty = false

      {Object.entries(days).map(([name, number])=>{
       /* let monthValue = signal !== '+' ? (parseFloat(result?.params?.[child.id]?.[name]) * - 1) : (parseFloat(result?.params?.[child.id]?.[name]) )*/
        let monthValue =  (parseFloat(result?.params?.[child.id]?.[name]) )

        if(monthValue.toString() != "NaN"){
          empty = false
          
        }
      })}
    
    if(empty == true && !showZeroLines){
        // if(empty == false && !showZeroLines){
      return null
    }else{

		  return (<React.Fragment>


                   <tr>
                      <td className={'comment-wrapper'} style={{paddingLeft: 20 + (10 * level)}}  onClick={()=>
                          !child.is_tail ? this.toggleRow(contabilParam, child) : this.fetchDetail(child.fr_id)
                      }>
                        {/*this.state.comments && <Comment
                            refreshComments={()=> this.setComments()}
                            comments={this.state.comments}
                            model_reference={'contabil_pattern'}
                            month={0}
                            year={0}
                            model_reference_id={child.key}>
                        </Comment>*/}
                        <div style={{marginRight: 10, display: 'inline-block'}}>

                          {!child.is_tail && <i className={`fa fa-chevron-${this.branchIsActive(child) ? 'down' : 'right'}`}/>}
                          {child.is_tail && <i className={`pull-left fa fa-chevron-${recordsFetched[child.fr_id] ? 'down' : 'right'}`}/>}
                        </div>

                        {child.label} {cash_flow_formula_ids[child.fr_id] ? <i className={"fa fa-star"} style={{color: "#31B6BC"}}/> : ""}
                      </td>
                      {Object.entries(days).map(([name, number])=>(
                          <td style={{paddingLeft: "76px !important"}} className={'comment-wrapper'}>
                            {/*this.state.comments && <Comment
                                refreshComments={()=> this.setComments()}
                                comments={this.state.comments}
                                model_reference={'balance_sheet'}
                                month={number}
                                monthName={name}
                                year={this.state.year}
                                model_reference_id={child.id}>
                            </Comment>*/}
                            {<DetailingResult
                                refreshDetailingResults={()=> this.setDetailingResults()}
                                detailingResults={this.state.comments}
                                model_reference={'balance_sheet'}
                                month={this.state.month}
                                monthName={number}
                                year={this.state.year}
                                type_detailing_result={""}
																lineParams={this.state.lineParams}
																visibleParams={this.state.visibleParams}
                                type_result={"day"}
                                model_reference_id={child.id}
                                model_reference_label={child.label}
                                reference_id={child.id}>
                            </DetailingResult>}
                            {code_contabil_pattern_param_list[child.id] !== undefined  && code_contabil_pattern_param_list[child.id][number] === true &&
                                <ManualLaunch style={{paddingLeft: 40 }}></ManualLaunch>
                            }
                            {/*result && result.params[child.id] && this.numberToCurrency(signal === '-' ? (parseFloat(result?.params?.[child.id]?.[name]) * - 1) : (parseFloat(result?.params?.[child.id]?.[name]) ))*/}
                            {result && result.params[child.id] && this.numberToCurrency( (parseFloat(result?.params?.[child.id]?.[name]) ))}

                          </td>
                      ))}
                     <td>
                       <ResultChartDay
                           loadAllYears={()=>{
                             this.state.years.map((year)=>{
                               this.fetchInitialParams( year, false,  this.state.month)
                             })
                           }}
                           loadYear={(year)=> this.fetchInitialParams(year, false, this.state.month)}
                           years={this.state.years}
                           year={this.state.year}
                           title={child.label}
                           parameter_id={child.id}
                           allYearsData={this.state.allYearsData}
                           dataKey={"balance-params"}
                           days={this.state.days}
                           month={this.state.month}
                       />
                     </td>
                   </tr>
                    {this.renderChildreenFor(child.fr_id, level)}
			        {this.branchIsActive(child) && <React.Fragment>
			        	{this.renderChildreen(child, child.childreen_attributes, (level + 1), contabilParam.label.trim() === 'Entradas' ? '+' : '-' )}
			        </React.Fragment>}
					
				</React.Fragment>)
      }
        	
    })
  	
  	
  	return childreen
  	
  }

  changeCalender(dateSelect){

    const currentYear = dateSelect.getFullYear();
    const currentMonth = (dateSelect.getMonth()+1).toString().padStart(2, '0');
    this.setState({year: currentYear})
    this.setState({month: currentMonth})
    this.setDays(currentYear, currentMonth )

    this.fetchInitialParams(currentYear,true,currentMonth )
    this.get_code_contabil_pattern_param_list(currentYear, currentMonth )
    this.props.changeYear(currentYear)
    this.props.changeMonth(currentMonth)
    this.getCheckBank(currentYear,currentMonth)
    this.getFinalBalance(currentYear,currentMonth)

    let self = this
    Object.entries(self.state.initial_balance_day).map(([name, number]) => {
      setTimeout(()=>{
        this.setState((oldState) => {
          oldState.initial_balance_day[name] = 0
          return {...oldState}
        })
      } ,10 * number)
    })

    Object.keys(this.state.recordsFetched).map((key, i)=>{
      setTimeout(()=>{
        this.fetchDetail(key, currentYear, false,currentMonth)
      },1000 * i)

    })
  }
  changeMonthYear(year, month){
    this.setState({year})
    this.setState({month})
    this.setDays(year, month )
    this.fetchInitialParams(year,true,month )
    this.get_code_contabil_pattern_param_list(year, month )
    this.props.changeYear(year)
    this.props.changeMonth(month)
    this.getCheckBank(year,month)
    this.getFinalBalance(year,month)
    
    let self = this
    Object.entries(self.state.initial_balance_day).map(([name, number]) => {
      setTimeout(()=>{
        this.setState((oldState) => {
          oldState.initial_balance_day[name] = 0
          return {...oldState}
        })
      } ,10 * number)
    })

    Object.keys(this.state.recordsFetched).map((key, i)=>{
      setTimeout(()=>{
        this.fetchDetail(key, year, false,month)
      },1000 * i)

    })

  }

  changeYear(year){
  	this.setState({year})
  	this.fetchInitialParams(year,true, this.state.month)
  	this.get_code_contabil_pattern_param_list(year, this.state.month)
  	this.props.changeYear(year)

    
    Object.keys(this.state.recordsFetched).map((key, i)=>{
      setTimeout(()=>{
        this.fetchDetail(key, year, false)
      },1000 * i)
      
    })
  }
  checkOpeningBalanceMonth(day_name, type_contabilParam = ""){

    let self = this;
    return this.numberToCurrency(self.state.initial_balance_day[day_name]);
  }
  checkMonth(month_name, type_contabilParam = ""){

  	let checagem = 0;
  	let saidas = [];
  	let sum_saidas = 0;
  	let entradas = [];
  	let entradas_count = 0;
  	let saidas_count = 0;
  	let sum_entradas = 0;
    let self = this;
  	const {data, result} = this.state;


  	this.state.data && this.state.data.map((contabilParam)=>{

          if(type_contabilParam === contabilParam.label){
            contabilParam.childreen_attributes.map((childreen)=>{
              if(childreen.label.trim() === 'Saídas'){
                if( saidas_count === 0){
                  childreen.childreen_attributes.map((saida)=>{
                    saidas.push(saida.id)
                  })
                  saidas_count = 1
                  saidas.map((saida)=>{
                    if(result.params[saida]){
                      if(result.params[saida][month_name]){
                      sum_saidas += parseFloat(result.params[saida][month_name])
                     }
                    }
                  })
                }
              }else{
                if( entradas_count === 0){
                  childreen.childreen_attributes.map((entrada)=>{
                    entradas.push(entrada.id)
                  })
                  entradas_count = 1
                  entradas.map((entrada)=>{
                    if(result.params[entrada]){
                      if(result.params[entrada][month_name]){
                        sum_entradas += parseFloat(result.params[entrada][month_name])
                      }
                    }
                  })
                }
              }
            })
          }


        checagem = sum_entradas + sum_saidas

  	})
    if(type_contabilParam.trim() === 'Operacional' ){
      self.state.result_operational_day[month_name] = checagem
    }else{
      self.state.result_not_operational_day[month_name] = checagem

    }

  	return this.numberToCurrency(checagem);
  }

  async getFinalBalance(year, month) {

    await axios.get(`/results/get_final_balance_day.json`, {
      params: {
        year: year,
        month: month
      }
    }).then(response => {
      this.setState((oldState)=>{
        oldState.initial_balance_day[response.data.day] =  response.data.value
        return {...oldState}
      })
      this.setState({day_number_selected: response.data.day})
      this.setState({value_initial_balance: response.data.value})

    })
  }
  getResultFinalMonthsSum(name, number){

    let self = this
    let last_days

    self.state.final_balance_day[number] =  self.state.initial_balance_day[number]  + self.state.result_operational_day[number] + self.state.result_not_operational_day[number]
    if(number + 1 !== self.state.month_number_selected ){
      self.state.initial_balance_day[ number + 1] = self.state.final_balance_day[number]
    }else{
      self.state.initial_balance_day[ number + 1] =   self.state.value_initial_balance
    }

    Object.entries(this.state.days).map(([name, num ])=>(
        last_days = num
    ))

    return this.numberToCurrency(self.state.final_balance_day[number])
  }

  vlSaldoBank( year,month ,day_name,day_number) {
    let self = this;
    //debugger

    //this.getCheckBank(year, month,day_name,day_number )

    return this.numberToCurrency( self.state.vls_saldo_bank[day_name]);
  }

  checkBank(year,month, day_name,day_number){
    let self = this;
    let value_month = 0
    const {vls_saldo_bank, final_balance_day} = this.state;

    if(vls_saldo_bank[day_name] !== 0) {
      value_month = vls_saldo_bank[day_name] - final_balance_day[day_name]
      self.state.check_bank[day_name] = value_month

      return this.numberToCurrency(value_month);
    } else {
      return "-";
    }
  }

  getResultMonthsSum(month_name){
    let checagem = 0
    const {result_operational_day, result_not_operational_day} = this.state;
    checagem = result_operational_day[month_name] + result_not_operational_day[month_name]

    return this.numberToCurrency(checagem);
  }
  changeVisibleDays(event){
    // event.target.name]: event.target.checked
    let {name, checked} = event.target
    let props = this.props;
    this.setState((oldState)=>{
      oldState.visibleDays[name] = checked
      oldState.days = {}
      Object.entries(oldState.visibleDays).map(([name, checked])=>{
        if(checked){
          oldState.days[name] = props.days[name]
        }
      })
      
      return {...oldState}
    });  
  }

  fetchConflicts(){
    axios.get('/configured_patterns/childreen_conflicts.json').then((conflicts)=>{
      this.setState({conflicts: conflicts?.data})
    })
  }

  // fetch get_periods using axios
  fetchGetPeriods(){
    axios.get('/liquid_profits/get_periods.json').then((periods)=>{
      this.setState({periods: periods?.data})
    })
  }

  render() {

    const {data, result, year, years, resultIds, days, cash_flow_formula_ids, showZeroLines, months, conflicts, month, default_days, default_weeks , days_foreseen_accomplished, code_contabil_pattern_param_list} = this.state;
    const {initial_days_foreseen_accomplished} = this.props
    let foreseen_accomplished = []

    let date = new Date(year, month, 0)

    if(Object.keys(days_foreseen_accomplished ).length !== 0 ){
      foreseen_accomplished = days_foreseen_accomplished
    }else{
      foreseen_accomplished = initial_days_foreseen_accomplished
    }

    return (
    	<React.Fragment>

          <br></br>
    		<div style={{display: 'flex', justifyContent: 'space-between' }}>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 20 }}>


			{/* <DayFilter changeVisibleDays={(e)=> this.changeVisibleDays(e)} visibleDays={this.state.visibleDays}  days={days}  ></DayFilter>

                {showZeroLines ?
                  <div title="Mostrar linhas zeradas" style={{color: 'rgb(36,44,86)', fontWeight: "bold"}} className={'pointer'} onClick={()=> this.setState({showZeroLines: !showZeroLines})}> <img src={olhoOff} style={{width: 36}}></img></div>
                  :
                  <div title="Ocultar linhas zeradas" style={{color: 'rgb(36,44,86)', fontWeight: "bold"}} className={'pointer'} onClick={()=> this.setState({showZeroLines: !showZeroLines})}> <img src={olhoOn} style={{width: 36}}></img></div>
                }
               <small style={{fontSize: 10, padding: "10px 0 0 43px", display: 'block', color: 'grey'}}>* Você está visualizando números no formato n/{this.props.divisor}</small>*/}
              </div>



	            <form className="form flex" style={{paddingRight: 35, alignItems: 'center', height: '100%'}}>
                  <div style={{marginLeft: 20}}>
                    <MonthRangeInput
                        date={date}
                        onChange={(dateSelect) => {
                          this.changeCalender(dateSelect)
                        }}
                    ></MonthRangeInput>
                  </div>


                  <a style={{marginLeft: 20}} onClick={()=> this.exportResult(month,year, this.state.initial_balance_day, this.state.final_balance_day, this.state.result_operational_day, this.state.result_not_operational_day,this.state.vls_saldo_bank, this.state.check_bank )} >
                      <img src={downloadIco} style={{width: 36}}></img>
                  </a>

	            </form>
	        </div>
          <br></br>


          {conflicts?.length > 0 && <div className={" alert alert-alert"} style={{padding: 10}}>
            <ul>
              {conflicts?.map(([code, companies, difference])=>{
                return <React.Fragment>
                          <li >{code} está parametrizado como {difference.reduce((a,b)=> a + ", " + b, "")}</li>
                      </React.Fragment>
              })}
            </ul>
          </div>}
          
	        <div id="table-result-wrapper">
	    		<div className="content margin-left-40 margin-right-40 padding-top-40 ">
		            <table id="table-result" className={"margin-bottom-20 without-acumulado balance-table"}>
		                <thead>
                            <tr>
                              <th></th>
                              {Object.entries(days ).map(([name, number])=>( foreseen_accomplished[number] !== undefined  && foreseen_accomplished[number] === true ? <th style={{color: "#1598d3",  textAlign: 'center'}}>REAL</th> : <th style={{color: "#a7d8ee",  textAlign: 'center'}}>PREV</th>)) }
                            </tr>
		                    <tr>
		                        <th>
		                            Nome
		                        </th>
                              {Object.entries(days ).map(([name, number])=>(
                                   <th style={{paddingLeft: "76px !important",
                                     color:  foreseen_accomplished[number] !== undefined  && foreseen_accomplished[number] === true ? "#1598d3": "#a7d8ee"
                                   }}>{  dayDict[`${number}`]} / {  default_weeks[`${number}`] }</th>
                                ))}

		                    </tr>

		                </thead>
                      {data.map((contabilParam)=>{
		                		return(  <React.Fragment>
                                  { contabilParam.label !== "Exclusão"  && <tbody key={contabilParam.id}>
                                  <tr  style={{borderBottom: '3px solid #E4E5E4'}}></tr>
                                  <tr id="principal" style={{borderBottom: '1px solid #E4E5E4'}}>
                                    <th className={'comment-wrapper'}>
                                      {this.state.comments && <Comment
                                          refreshComments={()=> this.setComments()}
                                          comments={this.state.comments}
                                          model_reference={'contabil_pattern'}
                                          month={0}
                                          year={0}
                                          model_reference_id={contabilParam.key}>
                                      </Comment>}
                                      {contabilParam.label.toUpperCase() }  {cash_flow_formula_ids[contabilParam.fr_id] ? <i className={"fa fa-star"} style={{color: "#31B6BC"}}/> : ""}
                                    </th>

                                  </tr>

                                  {
                                    contabilParam.childreen_attributes.map((child)=> {

                                      return( <React.Fragment>
                                        <tr id="principal">
                                          <th className={'comment-wrapper'}>
                                            {this.state.comments && <Comment
                                                refreshComments={()=> this.setComments()}
                                                comments={this.state.comments}
                                                model_reference={'contabil_pattern'}
                                                month={0}
                                                year={0}
                                                model_reference_id={child.key}>
                                            </Comment>}
                                            {child.label.toUpperCase()}  {cash_flow_formula_ids[child.fr_id] ? <i className={"fa fa-star"} style={{color: "#31B6BC"}}/> : ""}
                                          </th>
                                          {Object.entries(days).map(([name, number])=>(

                                              <th style={{paddingLeft: "76px !important",}} className={'comment-wrapper'}>
                                                {this.state.comments && <Comment
                                                    refreshComments={()=> this.setComments()}
                                                    comments={this.state.comments}
                                                    model_reference={'balance_sheet'}
                                                    month={number}
                                                    monthName={name}
                                                    year={this.state.year}
                                                    model_reference_id={child.id}>
                                                </Comment>}
                                                {code_contabil_pattern_param_list[child.id] !== undefined  && code_contabil_pattern_param_list[child.id][number] === true &&
                                                    <ManualLaunch style={{paddingLeft: 40 }}></ManualLaunch>
                                                }
                                                {
                                                    result  && (  this.numberToCurrency( parseFloat(result?.params?.[child.id]?.[name]) ))
                                                }
                                              </th>
                                          ))}
                                          <td >
                                            <ResultChartDay
                                                loadAllYears={()=>{
                                                  this.state.years.map((year)=>{
                                                    this.fetchInitialParams(year, false,   this.state.month)
                                                  })
                                                }}
                                                loadYear={(year)=> this.fetchInitialParams(year, false,   this.state.month)}
                                                years={this.state.years}
                                                year={this.state.year}
                                                title={child.label}
                                                parameter_id={child.id}
                                                allYearsData={this.state.allYearsData}
                                                dataKey={"balance-params"}
                                                days={days}
                                                month={this.state.month}
                                            />
                                          </td>
                                        </tr>

                                        {this.renderChildreen(child, child.childreen_attributes, 1, child.label.trim() !== 'Entradas'   ? '-': '+')}
                                      </React.Fragment>)

                                    })
                                  }
                                  <tr></tr>
                                  <tr>
                                    <td style={{
                                      backgroundColor:  "#e5e5e5",
                                      color: '#617E94',
                                      fontSize: 13
                                    }}> Supervit/Deficit - {contabilParam.label }</td>
                                    {Object.entries(days).map(([name, number])=>(
                                        <td style={{
                                          paddingLeft: "76px !important",
                                          backgroundColor:  "#e5e5e5",
                                          color: '#617E94',
                                          fontSize: 13
                                        }}>
                                          {result && this.checkMonth(name, contabilParam.label)  }
                                        </td>
                                    ))}


                                    <td >
                                      <ResultChartDay
                                          loadAllYears={()=>{
                                            this.state.years.map((year)=>{
                                              this.fetchDataParams(year,this.state.month, contabilParam.label)

                                            })
                                          }}
                                          loadYear={(year)=>  this.fetchDataParams(year,this.state.month, contabilParam.label)}
                                          years={this.state.allDataYears}
                                          year={this.state.year}
                                          title={"Supervit/Deficit - "+ contabilParam.label }
                                          keyLabel={contabilParam.label === "Operacional" ? "result_operational" : "result_not_operational"}
                                          allYearsData={ this.state.allData}
                                          dataKey={"balance-day-line"}
                                          days={days}
                                          month={this.state.month}

                                      />
                                    </td>
                                  </tr>
                                  </tbody>}
                      </React.Fragment>) })}
		                	<tbody>
                              <tr></tr>
                              <tr>
                                <td style={{
                                  backgroundColor:  "#b5c7dc",
                                  color: '#2A3170',
                                  fontSize: 13
                                }}> Movimentação do dia</td>
                                {Object.entries(days).map(([name, number])=>(
                                    <td style={{
                                      paddingLeft: "76px !important",
                                      backgroundColor:  "#b5c7dc",
                                      color: '#2A3170',
                                      fontSize: 13
                                    }}>
                                      {this.getResultMonthsSum(name)}
                                    </td>
                                ))}
                                <td >
                                  <ResultChartDay
                                      loadAllYears={()=>{
                                        this.state.years.map((year)=>{
                                          this.fetchDataParams(year,this.state.month, "result_months")

                                        })
                                      }}
                                      loadYear={(year)=>  this.fetchDataParams(year,this.state.month, "result_months")}
                                      years={this.state.allDataYears}
                                      year={this.state.year}
                                      title={"Movimentação do dia" }
                                      keyLabel={"result_months"}
                                      allYearsData={ this.state.allData}
                                      dataKey={"balance-day-line"}
                                      days={days}
                                      month={this.state.month}

                                  />
                                </td>
                              </tr>

                            </tbody>

                          <tbody>
                            <tr></tr>
                            <tr>

                              <td style={{
                                backgroundColor:  "#e5e5e5",
                                color: '#2A3170',
                                fontSize: 13
                              }}> Saldo Inicial</td>

                              {Object.entries(days).map(([name, number])=>(
                                  <td style={{
                                    paddingLeft: "76px !important",
                                    backgroundColor:  "#e5e5e5",
                                    color: '#2A3170',
                                    fontSize: 13
                                  }}>

                                    {  result && this.checkOpeningBalanceMonth(name)  }
                                  </td>
                              ))}
                              <td >
                                <ResultChartDay
                                    loadAllYears={()=>{
                                      this.state.years.map((year)=>{
                                        this.fetchDataParams(year,this.state.month, "initial_balance")

                                      })
                                    }}
                                    loadYear={(year)=>  this.fetchDataParams(year,this.state.month, "initial_balance")}
                                    years={this.state.allDataYears}
                                    year={this.state.year}
                                    title={"Saldo Inicial" }
                                    keyLabel={"initial_balance"}
                                    allYearsData={ this.state.allData}
                                    dataKey={"balance-day-line"}
                                    days={days}
                                    month={this.state.month}

                                />
                              </td>
                            </tr>
                            <tr>

                              <td style={{
                                backgroundColor:  "#e5e5e5",
                                color: '#2A3170',
                                fontSize: 13
                              }}> Saldo Final</td>
                              {Object.entries(days).map(([name, number])=>(
                                  <td style={{
                                    paddingLeft: "76px !important",
                                    backgroundColor:  "#e5e5e5",
                                    color: '#2A3170',
                                    fontSize: 13
                                  }}>
                                    {this.getResultFinalMonthsSum(name,number)}
                                  </td>
                              ))}
                              <td >
                                <ResultChartDay
                                    loadAllYears={()=>{
                                      this.state.years.map((year)=>{
                                        this.fetchDataParams(year,this.state.month, "final_balance")

                                      })
                                    }}
                                    loadYear={(year)=>  this.fetchDataParams(year,this.state.month, "final_balance")}
                                    years={this.state.allDataYears}
                                    year={this.state.year}
                                    title={"Saldo Final" }
                                    keyLabel={"final_balance"}
                                    allYearsData={ this.state.allData}
                                    dataKey={"balance-day-line"}
                                    days={days}
                                    month={this.state.month}

                                />
                              </td>
                            </tr>
                            <tr></tr>
                          </tbody>
                      {data.map((contabilParam)=>{
                        return( <React.Fragment>
                          { contabilParam.label === "Exclusão"  &&
                              <tbody key={contabilParam.id}>
                              <tr  style={{borderBottom: '3px solid #E4E5E4'}}></tr>
                              <tr id="principal" style={{borderBottom: '1px solid #E4E5E4'}}>
                                <th className={'comment-wrapper'}>
                                  {this.state.comments && <Comment
                                      refreshComments={()=> this.setComments()}
                                      comments={this.state.comments}
                                      model_reference={'contabil_pattern'}
                                      month={0}
                                      year={0}
                                      model_reference_id={contabilParam.key}>
                                  </Comment>}
                                  {contabilParam.label.toUpperCase() }  {cash_flow_formula_ids[contabilParam.fr_id] ? <i className={"fa fa-star"} style={{color: "#31B6BC"}}/> : ""}
                                </th>
                                {Object.entries(days).map(([name, number])=>(
                                    <th  className={'comment-wrapper'}>
                                      {/*this.state.comments && <Comment
                                          refreshComments={()=> this.setComments()}
                                          comments={this.state.comments}
                                          model_reference={'balance_sheet'}
                                          month={number}
                                          monthName={name}
                                          year={this.state.year}
                                          model_reference_id={contabilParam.id}>
                                      </Comment>*/}
                                      <DetailingResult
                                          refreshDetailingResults={()=> this.setDetailingResults()}
                                          detailingResults={this.state.comments}
                                          model_reference={'balance_sheet'}
                                          month={this.state.month}
                                          monthName={number}
																					lineParams={this.lineParams}
																					visibleParams={this.state.visibleParams}
                                          year={this.state.year}
                                          type_detailing_result={""}
                                          type_result={"day"}
                                          model_reference_id={contabilParam.id}
                                          model_reference_label={contabilParam.label}
                                          reference_id={"error"}>
                                      </DetailingResult>
                                      {result &&  ( this.numberToCurrency(result.params[contabilParam.id][name] ))}
                                    </th>
                                ))}
                                <td >
                                  <ResultChartDay
                                      loadAllYears={()=>{
                                        this.state.years.map((year)=>{
                                          this.fetchInitialParams(year, false,   this.state.month)
                                        })
                                      }}
                                      loadYear={(year)=> this.fetchInitialParams(year, false,   this.state.month)}
                                      years={this.state.years}
                                      year={this.state.year}
                                      title={contabilParam.label}
                                      parameter_id={contabilParam.id}
                                      allYearsData={this.state.allYearsData}
                                      dataKey={"balance-params"}
                                      days={days}
                                      month={this.state.month}
                                  />
                                </td>
                              </tr>
                              {this.renderChildreen(contabilParam, contabilParam.childreen_attributes, 1,  '+')}
                              </tbody> }
                        </React.Fragment>)})}
                          <tbody>
                            <tr></tr>
                            <tr id="principal">
                              <td style={{
                                backgroundColor:  "#fffdfd",
                                color: '#1598D3',
                                fontSize: 13
                              }}> Check c/ Banco</td>
                              {Object.entries(days).map(([name, number])=>(
                                  <td style={{
                                    paddingLeft: "76px !important",
                                    backgroundColor:  "#fffdfd",
                                    color: '#1598D3',
                                    fontSize: 13
                                  }} >
                                    {this.checkBank(this.state.year, this.state.month,name,number)}
                                  </td>
                              ))}

                            </tr>
                            <tr>
                              <td style={{
                                backgroundColor:  "#e5e5e5",
                                color: '#2A3170',
                                fontSize: 13
                              }}> Saldo Contas Bancárias </td>
                              {Object.entries(days).map(([name, number])=>(
                                  <td style={{
                                    paddingLeft: "76px !important",
                                    backgroundColor:  "#e5e5e5",
                                    color: '#2A3170',
                                    fontSize: 13
                                  }}>
                                    {this.vlSaldoBank(this.state.year, this.state.month,name,number)}
                                  </td>
                              ))}
                            </tr>

                          </tbody>
		            </table>
		        </div>
		     </div>		
		     <LoadingDialog open={this.requestsCounter > 0}/>	
    </React.Fragment>);
  }
}

export default Balance;

const monthDict = {
  "01" : "jan",
  "02" : "fev",
  "03" : "mar",
  "04" : "abr",
  "05" : "mai",
  "06" : "jun",
  "07" : "jul",
  "08" : "ago",
  "09" : "set",
  "10": "out", 
  "11": "nov", 
  "12": "dez", 
}
const dayDict = {
  "1" : "01",
  "2" : "02",
  "3" : "03",
  "4" : "04",
  "5" : "05",
  "6" : "06",
  "7" : "07",
  "8" : "08",
  "9" : "09",
  "10":  "10",
  "11":  "11",
  "12":  "12",
  "13":  "13",
  "14":  "14",
  "15":  "15",
  "16":  "16",
  "17":  "17",
  "18":  "18",
  "19":  "19",
  "20":  "20",
  "21":  "21",
  "22":  "22",
  "23":  "23",
  "24":  "24",
  "25":  "25",
  "26":  "26",
  "27":  "27",
  "28":  "28",
  "29":  "29",
  "30":  "30",
  "31":  "31",

}
//update
